import { StandaloneConfiguration } from '@amzn/sitc-frontend/components';
import { MicroAppRegistryInfo } from '@amzn/sitc-frontend-micro-app-sdk-core';
import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';

import { ErrorBoundary } from './components/ErrorBoundary';
import { microAppName } from './configs/app';

const configuration = { appId: 'studios-portal-app' };

const radioConfig = { channelNames: ['app'] };

const microApps: MicroAppRegistryInfo[] = [];

const StudiosDataMeshExplorer = React.lazy(() => import('./components/StudiosDataMeshExplorer'));

const rootComponent = () => (
  <StandaloneConfiguration
    configuration={configuration}
    microAppName={microAppName}
    microApps={microApps}
    radioConfig={radioConfig}
    RootComponent={StudiosDataMeshExplorer}
    standaloneModeFeatures={{ microAppUserPool: true }}
  />
);

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent,
  errorBoundary: (error: Error) => <ErrorBoundary error={error} />,
});

export const { bootstrap, mount, unmount } = lifecycles;
